import { ReactElement, HtmlHTMLAttributes } from 'react';
import { BounceLoader } from 'react-spinners';

import { useTheme } from 'hooks/useTheme';

import { LoaderContainer } from './styles';

interface ILoaderType {
  color?: string;
}

const Loader: React.FC<HtmlHTMLAttributes<HTMLDivElement> & ILoaderType> = ({
  color,
  ...rest
}): ReactElement => {
  const { theme } = useTheme();

  return (
    <LoaderContainer className="loader" {...rest}>
      <BounceLoader size={20} color={color || theme.colors.blue} loading />
    </LoaderContainer>
  );
};

export default Loader;
