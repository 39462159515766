import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';

import { ITheme, AvailableThemes } from './types';

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

export const getTheme = (key: AvailableThemes, theme: DefaultTheme): ITheme => {
  const themes = {
    blue: {
      background: theme.colors.blue,
      front: theme.colors.white,
    },
    'blue-light': {
      background: theme.colors.blueLight,
      front: theme.colors.white,
    },
    'blue-light-flat': {
      background: transparentize(0.85, theme.colors.blueLight),
      front: theme.colors.blueLight,
    },
    'gray-dark': {
      background: theme.colors.grayDark,
      front: theme.colors.white,
    },
    'gray-medium': {
      background: theme.colors.grayMedium,
      front: theme.colors.white,
    },
    'gray-medium-light': {
      background: theme.colors.grayMediumLight,
      front: theme.colors.paragraph,
    },

    white: {
      background: theme.colors.white,
      front: theme.colors.dark.color,
    },
    'white-orange': {
      background: theme.colors.white,
      front: theme.colors.orange,
    },

    orange: {
      background: theme.colors.orange,
      front: theme.colors.white,
    },
    'orange-flat': {
      background: transparentize(0.85, theme.colors.orange),
      front: theme.colors.orange,
    },

    green: {
      background: theme.colors.green,
      front: theme.colors.white,
    },

    light: {
      background: theme.colors.light.color,
      front: theme.colors.light.contrast,
    },
    medium: {
      background: theme.colors.medium.color,
      front: theme.colors.medium.contrast,
    },
    dark: {
      background: theme.colors.dark.color,
      front: theme.colors.dark.contrast,
    },
    'dark-flat': {
      background: transparentize(0.85, theme.colors.dark.color),
      front: theme.colors.dark.color,
    },
    'extra-dark': {
      background: theme.colors.extraDark.color,
      front: theme.colors.extraDark.contrast,
    },

    'transparent-gray': {
      background: 'transparent',
      front: theme.colors.extraDark.color,
      borderColor: theme.colors.extraDark.color,
    },

    confirmation: {
      background: theme.colors.confirmation.color,
      front: theme.colors.confirmation.contrast,
    },
    danger: {
      background: theme.colors.danger.color,
      front: theme.colors.danger.contrast,
    },
    warning: {
      background: theme.colors.warning.color,
      front: theme.colors.warning.contrast,
    },
    information: {
      background: theme.colors.information.color,
      front: theme.colors.information.contrast,
    },
  };

  return themes[key];
};
